#detach-button-host {
  display: none !important;
}

.ant-layout {
  .main-section {
    margin-left: 240px;
    background-color: rgb(239, 241, 245);

    @media screen and (max-width: 991px) {
      margin-left: 0px;
    }

    &.no-sider {
      margin: 0;
    }
  }

  .ant-layout-sider {
    background: #303136;

    .logo {
      font-weight: bold;
      color: #FFFFFF;
      font-size: 16px;
      display: flex;
      padding: 10px;
      align-items: center;
      border-bottom: 1px solid #666F80;

      .logo-img-wrapper {
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #0883EF;
        border-radius: 4px;
        margin-right: 12px;
      }
    }
  }
}

.ant-menu-dark {
  background-color: #303136;

  .ant-menu-item {
    border-radius: 0;

    &-selected {
      background-color: #25272C;
      margin-inline: 0;
      border-left: 3px solid #0883EF;
      width: 100%;
    }
  }
}

.ant-layout-sider-children {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.shadow-card {
  border: 1px solid #CED4D9;
  border-radius: 6px;
  background-color: #FFFFFF;
  box-shadow: 0 3px 6px 0 rgba(64, 96, 128, 0.1);
}

.main-content {
  padding: 20px;
}

.instructions-player-container {
  border: 1px solid #E0E6EC;
  border-radius: 2px;
  padding: 10px;
  display: inline-block;
  width: 100%;
}

.instructions-container {
  display: flex;
  flex-direction: column;
  justify-items: space-between;
  height: 100%;
  padding-top: 30px;
}

.read-more {
  display: flex;
  margin: auto 0 0;
}

.ic-bookmark-round {
  border-radius: 50%;
}

.bookmark-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: scroll;

  &.full-screen {
    position: fixed;
    z-index: 1000;
  }

  .bookmark-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 5;
    transform: translate(-50%, -50%);
    background-color: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0 5px 10px 0 rgba(61, 75, 102, 0.1);
    padding: 48px;

    @media screen and (max-width: 767px) {
      width: 90%;
      // height: 90%;
      top: 5%;
      left: 5%;
      transform: unset;
    }

    .close-btn {
      position: absolute;
      top: 24px;
      right: 24px;
      width: 32px;
      height: 32px;
      background-color: #F0F2F5;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:hover {
        background-color: darken($color: #F0F2F5, $amount: 2);
      }
    }

    .bookmark-icon {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background-color: #059DC1;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
    }

    .centered {
      text-align: center;
    }

    h2 {
      font-weight: bold;
      font-size: 20px;
      line-height: 26px;
      color: #252529;
      margin-bottom: 10px;
    }

    p {
      font-size: 14px;
      line-height: 20px;
      color: #676B73;
      margin-bottom: 30px;

      &.black {
        margin-bottom: unset;
        color: #252529;
      }
    }

    .time-container {
      padding: 10px 30px;
      border: 1px solid #D4DADE;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      color: #181A1F;
    }

    .add-bookmark {
      width: 100%;
      padding: 12px 20px;
      background-color: #059DC1;
      color: #FFFFFF;
      font-weight: bold;
      font-size: 15px;
      line-height: 18px;
      border: unset;
      border-radius: 2px;
      cursor: pointer;

      &:hover {
        background-color: darken($color: #059DC1, $amount: 2);
      }
    }

    .delete-bookmark {
      width: 100%;
      padding: 12px 20px;
      background-color: #ffffff;
      color: #CE3627;
      font-weight: bold;
      font-size: 15px;
      line-height: 18px;
      border: 1px solid #CE3627;
      border-radius: 2px;
      cursor: pointer;
      margin-top: 10px;

      &:hover {
        box-shadow: 0 0 11px rgba(33, 33, 33, .2);
      }
    }
  }
}

@media screen and (max-width: 767px) {

  .ant-picker-panels {
    flex-direction: column;
  }

  .mobile-range-picker-btn {
    margin-left: 15px;
  }
}

@media screen and (max-width: 576px) {

  .mobile-range-picker {
    width: 100%;
  }

  .mobile-range-picker-btn {
    margin: 10px 0;
    width: 100%;
  }
}

.attachments {
  margin-top: 24px;
  padding-bottom: 24px;

  p {
    font-size: 15px;
    line-height: 18px;
    color: #686C74;
    padding-bottom: 10px;
    border-bottom: 1px solid #F0F2F5;
  }

  .file-card {
    padding: 13px 20px;
    background-color: #F0F2F5;
    display: inline-flex;
    margin-right: 15px;

    .icon-wrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .file-info-wrap {
      padding-left: 14px;

      span {
        font-size: 15px;
        line-height: 18px;
        color: #252529;
        margin-bottom: 5px;
        cursor: pointer;
      }
    }
  }
}

.chapter-navigation {
  cursor: pointer;
  // padding: 16px;

  &:hover {
    background-color: #f7f7f7;
  }

  &.disabled {
    opacity: .6;
    cursor: unset;

    &:hover {
      background-color: unset;
    }
  }

  .title-wrap {
    display: flex;
    margin-bottom: 12px;
    position: relative;

    b {
      font-size: 17px;
      line-height: 20px;
      color: #252529;
      display: inline-block;
      margin-left: 30px;
    }
  }


  &.right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    p {
      text-align: right;
    }

    .title-wrap {

      b {
        margin-right: 30px;
        margin-left: 0;
      }
    }
  }
}

.ask-lecturer-modal {

  .ant-modal-footer {
    border-top: unset;
  }
}

.custom-header {
  background-color: #FFFFFF;
  padding: 12px 24px;
  box-shadow: 0 1px 4px 0 rgba(0, 21, 41, 0.08), 0 1px 0 0 #E2E7EA;

  @media screen and (max-width: 991px) {

    .go-back-btn {
      margin-left: 30px;
    }
  }

  @media screen and (max-width: 768px) {
    margin: -8px -8px 24px;
  }
}

.flags {
  margin-left: 11px;
  white-space: nowrap;

  button {
    padding: 0;
    background-color: unset;
    border: unset;

    &:hover {
      cursor: pointer;
    }

    img {
      margin: 0 2px;
      width: 20px;
      height: 15px;
      border-radius: 2px;

      &.active {
        -webkit-box-shadow: 0px 0px 10px 2px rgba(99, 174, 255, 0.84);
        -moz-box-shadow: 0px 0px 10px 2px rgba(99, 174, 255, 0.84);
        box-shadow: 0px 0px 10px 2px rgba(99, 174, 255, 0.84);

      }
    }
  }
}

.bottom .btn {
  background: #FFFFFF;
  color: #000000;
  border-radius: 4px;
  padding: 5px 20px;
  display: block;

  &.ghost {
    background-color: unset;
    border: 1px solid #FFFFFF;
    color: #FFFFFF;
    text-align: center;
  }
}